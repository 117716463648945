import { React, Page, SEO, CenterWrapper, StyleSheet, navigate } from 'lib'
import { howToUseData } from '../how-to-use'
import { PageTitle, VideoComponent } from 'components'
import { HTMLStylesDark, Theme } from 'app'

interface HowToUseVideoPageProps {
  params: {
    slug: string;
  }
}

function HowToUseVideo({ params }: HowToUseVideoPageProps) {
  const post = howToUseData.find(data => data.slug === params.slug)

  if (!post && typeof window !== 'undefined') {
    navigate('/404')
    return null
  }

  return (
    <Page whiteBackground>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
        <SEO doNotTrack title={'Guidance'} description={post?.title}/>
        <PageTitle canGoBack location='Guidance' title={post?.title}/>
        <VideoComponent post={post}/>
      </CenterWrapper>
    </Page>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    ...Theme.flex,
    marginTop: Theme.spacing(20),
    marginBottom: Theme.spacing(5),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),
      marginBottom: Theme.spacing(5),
    },
  },
  innerWrapper: {
    ...HTMLStylesDark.plainText,
    gap: Theme.spacing(3),
    flexDirection: 'column',
  },
})

export default HowToUseVideo
